import React, { useEffect, useState } from 'react';
import { Modal, Input, Form } from 'antd';

const RaiseDuplicateModal = ({ visible, onOk, onCancel, refId }) => {
  const [referenceLeadId, setReferenceLeadId] = useState('');
  const [issueDetail, setIssueDetail] = useState('');

  const handleOk = () => {
    if (referenceLeadId && issueDetail) {
      onOk(referenceLeadId, issueDetail);
    }
  };

  useEffect(() => {
    setReferenceLeadId('');
    setIssueDetail('');
  }, [visible]);

  return (
    <Modal
      title="Lead Details"
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <Form layout="vertical">
        <Form.Item label="Lead ID" disabled>
          <Input value={refId} disabled />
        </Form.Item>
        <Form.Item label="Reference Lead ID">
          <Input
            value={referenceLeadId}
            onChange={(e) => setReferenceLeadId(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input.TextArea
            value={issueDetail}
            onChange={(e) => setIssueDetail(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RaiseDuplicateModal;