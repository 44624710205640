import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox , } from "antd";
import { Row, Container, Col,Carousel } from "react-bootstrap";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import main from "../assets/main.png";
import logo from "../assets/logo.png";
import { loginAction, setUser } from '../store/action/common/authAction';
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { Spin } from "antd";


const RegistrationPage = (props) => {

    const navigate = useNavigate()

    const onFinish = (values) => {
      // Dispatch Redux Saga action
        props.loginAction_(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
      };

    useEffect(() => {
        if (props.loginResponse && props.loginResponse.accessToken) {
            localStorage.setItem('accessToken', props.loginResponse.accessToken);
            localStorage.setItem('refreshToken', props.loginResponse.refreshToken);
            localStorage.setItem('email', JSON.stringify(props.loginResponse.userData.email));
            localStorage.setItem('role', props.loginResponse.userData.role);
            localStorage.setItem('name', props.loginResponse.userData.name);
            props.setUser_({email: localStorage.getItem("email"), role: localStorage.getItem("role"), name: localStorage.getItem("name")});
            if (props.loginResponse.userData.role === "business") {
                navigate('/');
            } else if (props.loginResponse.userData.role === "admin") {
                navigate('/admin');
            }
        }
    }, [props.loginResponse])


  return (
    <div className="container-fulid"
      style={{ height: "100vh", backgroundColor: "#E6EDF3" }} >
      <Row className="h-100">
        {/* Left Panel */}
        <Col md={6} className="d-flex flex-column justify-content-center align-items-center bg-white p-5" >
          <div style={{ maxWidth: "400px", width: "100%" }}>
            <img src={logo} alt="Logo" className="mb-4" style={{ width: "100px" }}/>
            <h1 className="mb-3" style={{ fontSize: "1.8rem" }}>Login to your account</h1>
            <p className="text-muted mb-4">Enter your credentials below</p>

            <Form name="loginForm" layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}
              style={{ maxWidth: "400px",margin: "auto",background: "#fff",padding: "20px",borderRadius: "8px", 
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",}}>
              <Form.Item name="email" label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },]}>
                <Input placeholder="Email" size="large" style={{ borderRadius: "5px", padding: "10px" }} />
              </Form.Item>

              <Form.Item name="password" label="Password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  {min: 6, message: "Password must be at least 6 characters!", }, ]}>
                <Input.Password placeholder="Password" size="large"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  style={{ borderRadius: "5px", padding: "10px" }}/>
              </Form.Item>

              <div style={{ display: "flex",justifyContent: "space-between",alignItems: "center", marginBottom: "16px", }}>
                <Checkbox>Remember me</Checkbox>
                <a href="#" style={{ color: "#0048FF" }}>
                  Forgot password?
                </a>
              </div>

              <Form.Item>
                <Button type="primary" htmlType="submit" size="large"
                    style={{ width: "100%",borderRadius: "5px", backgroundColor: "#0048FF", borderColor: "#0048FF"}}
                    loading={props.loading}  disabled={props.loading} >
                    {props.loading ? <Spin /> : "Login"} 
                </Button>
                </Form.Item>
            </Form>
            <p className="mt-3">
              Don’t have an account?{" "}
              <a href="https://blaccdot.com/selectservices" target="_blank" className="text-primary">
                Sign up
              </a>
            </p>
          </div>
        </Col>

{/* Right Panel */}
<Col
  md={6}
  className="d-none d-md-flex flex-column justify-content-center align-items-center text-white loginCarousel"
  style={{ backgroundColor: "#075AD8", height: "100vh", textAlign: "center" }}>
  <Carousel controls={false} indicators={false} interval={3000} pause={false}>
    {/* Slide 1 */}
    <Carousel.Item>
      <img src={main} alt="Illustration-1" className="d-block" style={{ width: "60%",margin: 'auto'}} />
    </Carousel.Item>
    {/* Slide 2 */}
    <Carousel.Item>
      <img src={main} alt="Illustration 2" className="d-block" style={{ width: "60%",margin: 'auto'}} />
    </Carousel.Item>
    {/* Slide 3 */}
    <Carousel.Item>
      <img src={main} alt="Illustration 3" className="d-block" style={{ width: "60%",margin: 'auto'}} />
    </Carousel.Item>
  </Carousel>

  <h2 style={{ fontSize: "1.5rem", marginTop: "20px" }}>Connect with every application.</h2>
  <p
    className="text-center"
    style={{
      maxWidth: "400px",
      fontSize: "1rem",
      marginTop: "10px",
    }}
  >
    Everything you need in an easily customizable dashboard.
  </p>
</Col>


      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
    loginResponse: state.login.response,
    loading: state.login.loading, // Map the loading state

})

const mapDispatchToProps = (dispatch) => ({
    loginAction_: (data) => {
        dispatch(loginAction(data));
    },
    setUser_: (data) => {
      dispatch(setUser(data));
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationPage);




// import React, { useEffect } from 'react';
// import { Row, Col, Image, Form, Input, Button, Divider } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { loginAction, setUser } from '../store/action/common/authAction';
// import './styles.css';

// const RegistrationPage = (props) => {

//     const navigate = useNavigate();
//     const onFinish = (values) => {
//         props.loginAction_(values);
//     };
//     const onFinishFailed = (errorInfo) => {
//         alert('Loggin Errro', errorInfo)
//     };

//     useEffect(() => {
//         if (props.loginResponse && props.loginResponse.accessToken) {
//             localStorage.setItem('accessToken', props.loginResponse.accessToken);
//             localStorage.setItem('refreshToken', props.loginResponse.refreshToken);
//             localStorage.setItem('email', JSON.stringify(props.loginResponse.userData.email));
//             localStorage.setItem('role', props.loginResponse.userData.role);
//             localStorage.setItem('name', props.loginResponse.userData.name);
//             props.setUser_({email: localStorage.getItem("email"), role: localStorage.getItem("role"), name: localStorage.getItem("name")});
//             if (props.loginResponse.userData.role === "business") {
//                 navigate('/');
//             } else if (props.loginResponse.userData.role === "admin") {
//                 navigate('/admin');
//             }
//         }
//     }, [props.loginResponse])

//     return (
//         <div className='main-container'>
//             <Col span={12}>
//                 <Image src="./loginImage.jpg" alt="Registration Image" preview={false} />
//             </Col>
//             <Col span={12}>
//                 <Row   >
//                 <h1>Mohammed Fareestha All test Mohammed</h1>
//                     <Form
//                         name="basic"
//                         style={{ position: "relative", left: "-15%" }}
//                         labelCol={{
//                             span: 14,
//                         }}
//                         wrapperCol={{
//                             span: 20,
//                         }}
//                         initialValues={{
//                             remember: true,
//                         }}
//                         onFinish={onFinish}
//                         onFinishFailed={onFinishFailed}
//                         autoComplete="off" >

//                         <Form.Item
//                             label="Email"
//                             name="email"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: '!!!Please enter value',
//                                 },]}>
//                             <Input style={{ width: "22rem" }} />
//                         </Form.Item>

//                         <Form.Item
//                             label="Password"
//                             name="password"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: '!!!Please enter value',
//                                 },
//                             ]}>
//                             <Input type='password' style={{ width: "22rem" }} />
//                         </Form.Item>

//                         <Form.Item
//                             wrapperCol={{
//                                 offset: 14,
//                                 span: 10,
//                             }}>
//                             <Button type="primary" htmlType="submit" style={{ width: "15rem", height: "2rem" }}>
//                                 <h3>Login</h3>
//                             </Button>
//                         </Form.Item>
//                     </Form>
//                 </Row>
//                 <Divider>OR</Divider>
//                 <div style={{ position: "relative", left: "20%" }}> Don’t have an account? Get started here  <span><Button type='primary' onClick={() => navigate("/sign-up")}>Sign up</Button></span> </div>
//             </Col>
//         </div>
//     );
// };

// const mapStateToProps = state => ({
//     loginResponse: state.login.response,
// })

// const mapDispatchToProps = dispatch => ({
//     loginAction_: (data) => {
//         dispatch(loginAction(data));
//     },
//     setUser_: (data) => {
//       dispatch(setUser(data));
//     },
// })

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(RegistrationPage);
