import React, { useEffect } from 'react';
import { Row, Col, Image } from 'antd';
import './styles.css';
const RedirectMail = () => {
    const login = (values) => {
        sessionStorage.setItem('user', JSON.stringify({ role: values }));
        window.location.reload();
    };

    const onProceed = (values) => {
        window.location.href = '/password';
    };



    return (
        <div className='main-container'>
            <Col span={11}>
                <Image src="./emailcharacter.jpeg" alt="emailcharacter Image" preview={false} style={{ width: "90%" }} />
            </Col>
            <Col span={11}>
                <Row>
                    <h1>A verification link has been sent to your email. Please take a moment to check your inbox and click the link to continue with the <span style={{ cursor: "pointer" }} onClick={() => onProceed()}> process</span>.</h1>
                </Row>
            </Col>
        </div>
    );
};

export default RedirectMail;