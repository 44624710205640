import * as type from '../../types';

export function loginAction(data) {
    return {
        type: type.FETCH_LOGIN,
        payload: data
    }
}

export function setUser(data) {
    return {
        type: type.SET_USER,
        data
    }
}