import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const ComparisonChart = () => {
    const [chartType, setChartType] = useState('area');

    const chartOptions = {
        chart: {
            type: chartType,
        },
        title: {
            text: 'Leads vs Businesses',
        },
        xaxis: {
            categories: ['Ontario', 'Quebec', 'British Columbia', 'Alberta', 'Manitoba', 'Saskatchewan', 'Nova Scotia', 'New Brunswick', 'Newfoundland and Labrador', 'Prince Edward Island'],
        },
    };

    const chartSeries = [
        {
            name: 'Leads',
            data: [80, 50, 30, 100, 60, 70, 90, 40, 50, 80],
        },
        {
            name: 'Businesses',
            data: [20, 40, 80, 20, 50, 60, 30, 70, 90, 40],
        },
    ];

    const handleChartTypeChange = (type) => {
        setChartType(type);
    };

    return (
        <div>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
                {['line', 'bar', 'area'].map((type) => (
                    <div
                        key={type}
                        onClick={() => handleChartTypeChange(type)}
                        style={{
                            padding: '10px 20px',
                            cursor: 'pointer',
                            backgroundColor: chartType === type ? '#007bff' : '#f8f9fa',
                            color: chartType === type ? '#fff' : '#000',
                            border: '1px solid #dee2e6',
                            borderRadius: '4px',
                            marginRight: '5px',
                        }}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </div>
                ))}
            </div>
            <Chart options={chartOptions} series={chartSeries} type={chartType} height={350} />
        </div>
    );
};

export default ComparisonChart;