import { Row, Col, Card, Typography } from 'antd';
import LeadsTable from '../components/Leads/LeadsTable';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllLeads } from '../store/action/business/LeadAction';
import { getAllAdminLeads } from '../store/action/admin/LeadAdminAction';

const MyLeads = (props) => {
    const { Title } = Typography;
    const [leads, setLeads] = useState([]);
    useEffect(() => {
        if (props.user && props.user.role) {
            if (props.user.role === 'admin') {
                props.getAllAdminLeads_();
            } else {
                props.getAllLeads_();
            }
        }
    }, []);

    useEffect(() => {
        if (props.user && props.user.role) {
            if (props.user.role === 'admin') {
                setLeads(props.allLeadsAdmin);
            } else {
                setLeads(props.allLeads);
            }
        }
    }, [props.allLeadsAdmin, props.allLeads]);
    return (
        <div style={{ padding: 20 }}>
            <Title level={2}>My Leads</Title>
            <Row gutter={20} style={{ marginTop: 20 }}>
                <Col span={24}>
                    <Card style={{ height: '100%' }}>
                        <LeadsTable leads={leads} mini={false} expand={true} />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user.response,
    allLeadsAdmin: state.allLeadsAdmin.response.leads,
    allLeads: state.allLeads.response.leads
})

const mapDispatchToProps = dispatch => ({
    getAllLeads_: () => {
        dispatch(getAllLeads());
    },
    getAllAdminLeads_: () => {
        dispatch(getAllAdminLeads());
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyLeads);
