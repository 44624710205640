import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { getAllTxnApi, getWalletAmountApi } from '../../api/business/LeadApi';

function* fetchAllTxnSaga(action) {
    try {
       const data = yield call(getAllTxnApi);
       yield put({type: type.FETCH_GET_ALL_TXNS_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_GET_ALL_TXNS_FAILURE, message: e.message});
    }
}

function* fetchWalletSaga(action) {
    try {
       const data = yield call(getWalletAmountApi);
       yield put({type: type.FETCH_GET_WALLET_AMOUNT_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_GET_WALLET_AMOUNT_FAILURE, message: e.message});
    }
}
 


export function* watchFetchAllTxnSaga() {
    yield takeEvery(type.FETCH_GET_ALL_TXNS, fetchAllTxnSaga);
}
export function* watchFetchWalletSaga() {
    yield takeEvery(type.FETCH_GET_WALLET_AMOUNT, fetchWalletSaga);
}
 