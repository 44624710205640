import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Form, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import './styles.css';


const RegistrationPage = () => {
    const [mismatch, setMismatch] = useState(false);

    const login = (values) => {
        sessionStorage.setItem('user', JSON.stringify({ role: values }));
        window.location.reload();
    };

    const onCompare = (pass1, pass2) => {
        if (String(pass1).trim() === String(pass2).trim()) {
        } else {
            setMismatch(true);
        }
    };

    const onFinish = (values) => {
        const pass1 = values && values.newpassword;
        const pass2 = values && values.confirmpassword;
        onCompare(pass1, pass2);
        console.log('Mohammed clicked')
    };

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user) {
            if (user.role === "business") {
                window.location.href = '/';
            } else if (user.role === "admin") {
                window.location.href = '/admin';
            }
        }
    }, [])


    return (
        <div className='main-container'>
            <Col span={12}>
                <Image src="./loginImage.jpg" alt="Registration Image" preview={false} />
            </Col>
            <Col span={12}>
                <Row>
                    <Form
                        name="basic"
                        style={{ position: "relative", left: "-15%" }}
                        labelCol={{
                            span: 14,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >


                        <Form.Item
                            label="Enter your new password"
                            name="newpassword"
                            rules={[
                                {
                                    required: true,
                                    message: '!!!Please enter value',
                                },
                            ]}
                        >
                            <Input style={{ width: "22rem" }} />
                        </Form.Item>

                        <Form.Item
                            label="Confirm your password"
                            name="confirmpassword"
                            rules={[
                                {
                                    required: true,
                                    message: '!!!Please enter value',
                                },
                            ]}
                        >
                            <Input style={{ width: "22rem" }} />
                            {mismatch ? <div style={{ color: "red" }}> Your confirm password must exactly match the new password.</div> : null}
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 14,
                                span: 10,
                            }}
                        >
                            <Button type="primary" htmlType="submit" style={{ width: "15rem", height: "2rem" }}>
                                <h3>Sign up</h3>
                            </Button>
                        </Form.Item>
                    </Form>
                </Row>

            </Col>
        </div >
    );
};

export default RegistrationPage;