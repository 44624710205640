import * as type from '../../types';

export function getAllTXn() {
    return {
        type: type.FETCH_GET_ALL_TXNS
    }
}

export function getWalletAmount() {
    return {
        type: type.FETCH_GET_WALLET_AMOUNT
    }
}