import axios from "./axios";

export const getApi = (url) =>
    new Promise((resolve, reject) => {
        axios
            .get(url)
            .then((response) => {
                if (response.data && response.data.error) reject(response.data.error);
                else resolve(response.data);
            })
            .catch((e) => reject(e));
    });

export const postApi = (url, data) =>
    new Promise((resolve, reject) => {
        axios
            .post(url, data)
            .then((response) => {
                if (response.data && response.data.error) reject(response.data.error);
                else resolve(response.data);
            })
            .catch((e) => reject(e));
    });

export const putApi = (url, data) =>
    new Promise((resolve, reject) => {
        axios
            .put(url, data)
            .then((response) => {
                if (response.data && response.data.error) reject(response.data.error);
                else resolve(response.data);
            })
            .catch((e) => reject(e));
    });

export const deleteApi = (url) =>
    new Promise((resolve, reject) => {
        axios
            .delete(url)
            .then((response) => {
                if (response.data && response.data.error) reject(response.data.error);
                else resolve(response.data);
            })
            .catch((e) => reject(e));
    });
