import * as type from '../../types';

export function createLead(data) {
    return {
        type: type.FETCH_CREATE_LEAD,
        payload: data
    }
}

export function getAllAdminLeads() {
    return {
        type: type.FETCH_GET_ALL_ADMIN_LEADS
    }
}

export function getDuplicateTickets() {
    return {
        type: type.FETCH_GET_DUPLICATE_TICKETS
    }
}