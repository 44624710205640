import { Card, Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import LeadsTable from "../components/Leads/LeadsTable";
import BusinessesTable from "../components/Businesses/EnrolledBusinessesTable";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllBusiness } from "../store/action/admin/BusinessAdminAction";

const Businesses = (props) => {

    const [businesses, setBusinesses] = useState([]);

    useEffect(() => {
        props.getAllBusiness_();
    }, []);

    useEffect(() => {
        console.log(props.businessList);
        if (props.businessList && props.businessList.businesses && props.businessList.businesses.length > 0) {
            setBusinesses(props.businessList.businesses);
        }
    }, [props.businessList]);
    return (
        <div style={{ padding: 20 }}>
            <Title level={2}>Enrolled Businesses</Title>
            <Row gutter={20} style={{ marginTop: 20 }}>
                <Col span={24}>
                    <Card style={{ height: '100%' }}>
                        <BusinessesTable data={businesses} mini={false} expand={true} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
const mapStateToProps = state => ({
  businessCard: state.businesscard.response,
  thisWeekLeadBusiness: state.thisWeekLeadBusiness.response,
  businessList: state.businessList.response,
})

const mapDispatchToProps = dispatch => ({
  getAllBusiness_: ()=>{
      dispatch(getAllBusiness());
  },
}) 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Businesses);