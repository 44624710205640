import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { getAllLeadApi, getDuplicateApi, getThisWeekBusinessLeadApi, submitDuplicateApi } from '../../api/business/LeadApi';

function* fetchAllLeadsSaga(action) {
    try {
       const data = yield call(getAllLeadApi);
       yield put({type: type.FETCH_GET_ALL_LEADS_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_GET_ALL_LEADS_FAILURE, message: e.message});
    }
}

function* fetchAllThisWeekLeadsBusinessSaga(action) {
    try {
       const data = yield call(getThisWeekBusinessLeadApi);
       yield put({type: type.FETCH_THIS_WEEK_LEADS_SUCCESS_BUSINESS, data});
    } catch (e) {
       yield put({type: type.FETCH_THIS_WEEK_LEADS_FAILURE_BUSINESS, message: e.message});
    }
}

function* fetchSubmitDuplicateSaga(action) {
    try {
       const data = yield call(submitDuplicateApi, action.data);
       yield put({type: type.FETCH_SUBMIT_DUPLICATE_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_SUBMIT_DUPLICATE_FAILURE, message: e.message});
    }
}
function* fetchAllTicketsSaga(action) {
    try {
        const data = yield call(getDuplicateApi, action.data);
        yield put({type: type.FETCH_GET_DUPLICATE_TICKETS_SUCCESS, data});
     } catch (e) {
        yield put({type: type.FETCH_GET_DUPLICATE_TICKETS_FAILURE, message: e.message});
     }
}

export function* watchFetchAllLeadsSaga() {
    yield takeEvery(type.FETCH_GET_ALL_LEADS, fetchAllLeadsSaga);
}
export function* watchFetchAllThisWeekLeadsBusinessSaga() {
    yield takeEvery(type.FETCH_THIS_WEEK_LEADS_BUSINESS, fetchAllThisWeekLeadsBusinessSaga);
}
export function* watchFetchSubmitDuplicateSaga() {
    yield takeEvery(type.FETCH_SUBMIT_DUPLICATE, fetchSubmitDuplicateSaga);
}
export function* watchFetchAllTicketsSaga() {
    yield takeEvery(type.FETCH_GET_DUPLICATE_TICKETS, fetchAllTicketsSaga);
}
 