import React, { useEffect } from 'react';
import { Row, Col, Image, Form, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';
import './styles.css'

const RegistrationPage = () => {

  const onFinish = (values) => {
    window.location.href = '/redirect-mail';
  };
  const onFinishFailed = (errorInfo) => {
    
  };




  return (
    <div className='main-container'>
      <Col span={12}>
        <Image src="./loginImage.jpg" alt="Registration Image" preview={false} />
      </Col>
      <Col span={12}>
        <Row   >

          <Form name="basic" labelCol={{span: 12,}}
            wrapperCol={{span: 16,}}
            initialValues={{remember: true,}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
            autoComplete="off"
            labelAlign="left"ß>
            <Form.Item
              label={<div>Company Name <span style={{ color: "red" }}>*</span></div>}
              name="companyname"
              rules={[
                {
                  required: true,
                  message: <p><ExclamationCircleFilled /> This field is required </p>
                },]}>
              <Input style={{ width: "22rem" }} />
            </Form.Item>

            <Form.Item
              label={<div>How many jobs are you able to complete each day? <span style={{ color: "red" }}>*</span></div>}
              name="job per day"
              rules={[
                {
                  required: true,
                  message: <p><ExclamationCircleFilled /> This field is required</p>,
                },
              ]}
            >
              <Input style={{ width: "22rem" }} />
            </Form.Item>

            <Form.Item
              label={<div>Team Strength <span style={{ color: "red" }}>*</span></div>}
              name="team strength"
              rules={[
                {
                  required: true,
                  message: <p><ExclamationCircleFilled /> This field is required</p>,
                },
              ]}
            >
              <Input style={{ width: "22rem" }} />
            </Form.Item>

            <Form.Item
              label={<div>Company's Location <span style={{ color: "red" }}>*</span></div>}
              name="location"
              rules={[
                {
                  required: true,
                  message: <p><ExclamationCircleFilled /> This field is required</p>,
                },]}>
              <Input style={{ width: "22rem" }} />
            </Form.Item>

            <Form.Item
              label={<div>Phone Number <span style={{ color: "red" }}>*</span></div>}
              name="phone"
              rules={[
                {
                  required: true,
                  message: <p><ExclamationCircleFilled /> This field is required</p>,
                },]}>
              <Input style={{ width: "22rem" }} />
            </Form.Item>

            <Form.Item
              label={<div>Company Started Date <span style={{ color: "red" }}>*</span></div>}
              name="start date"
              getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
              getValueProps={(i) => moment(i)}
              rules={[
                {
                  required: true,
                  message: <p><ExclamationCircleFilled /> This field is required</p>,
                },
              ]}
            >
              <DatePicker format='YYYY-MM-DD' style={{ width: '22rem' }} />
            </Form.Item>

            <Form.Item
              label={<div >GMB Profile URL <span style={{ color: "red" }}>*</span></div>}
              name="gmb url"
              rules={[
                {
                  required: true,
                  message: <p><ExclamationCircleFilled /> This field is required</p>,
                },
              ]}
            >
              <Input style={{ width: "22rem" }} />
            </Form.Item>

            <Form.Item
              label={<div>Busines's MailId <span style={{ color: "red" }}>*</span></div>}
              name="mailid"
              rules={[
                {
                  required: true,
                  message: <p><ExclamationCircleFilled /> This field is required</p>,
                },
              ]}
            >
              <Input style={{ width: "22rem" }} />
            </Form.Item>

            <Form.Item style={{ textAlign: "end" }}>
              <Button type="primary" htmlType="submit" style={{ width: "15rem", height: "2rem" }}>
                <h3>Sign up</h3>
              </Button>
            </Form.Item>
          </Form>
        </Row>

      </Col>
    </div>
  );
};

export default RegistrationPage;