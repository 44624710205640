import * as type from '../../types';

export function getAllAdminCards() {
    return {
        type: type.FETCH_ALL_ADMIN_CARDS,
    }
}

export function getThisWeekLeads() {
    return {
        type: type.FETCH_THIS_WEEK_LEADS,
    }
}

export function getThisWeekPayments() {
    return {
        type: type.FETCH_THIS_WEEK_PAYMENTS,
    }
}

export function getThisWeekBusiness() {
    return {
        type: type.FETCH_THIS_WEEK_BUSINESS,
    }
}