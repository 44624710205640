
export const SET_USER = "SET_USER";

export const FETCH_LOGIN = "FETCH_LOGIN";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";
export const FETCH_LOGIN_CLEAR = "FETCH_LOGIN_CLEAR";

export const FETCH_ALL_BUSINESS_CARDS = "FETCH_ALL_BUSINESS_CARDS";
export const FETCH_ALL_BUSINESS_CARDS_SUCCESS = "FETCH_ALL_BUSINESS_CARDS_SUCCESS";
export const FETCH_ALL_BUSINESS_CARDS_FAILURE = "FETCH_ALL_BUSINESS_CARDS_FAILURE";

export const FETCH_CREATE_CAMPAIGN = "FETCH_CREATE_CAMPAIGN";
export const FETCH_CREATE_CAMPAIGN_SUCCESS = "FETCH_CREATE_CAMPAIGN_SUCCESS";
export const FETCH_CREATE_CAMPAIGN_FAILURE = "FETCH_CREATE_CAMPAIGN_FAILURE";

export const FETCH_ALL_ADMIN_CARDS = "FETCH_ALL_ADMIN_CARDS";
export const FETCH_ALL_ADMIN_CARDS_SUCCESS = "FETCH_ALL_ADMIN_CARDS_SUCCESS";
export const FETCH_ALL_ADMIN_CARDS_FAILURE = "FETCH_ALL_ADMIN_CARDS_FAILURE";

export const FETCH_CREATE_LEAD = "FETCH_CREATE_LEAD";
export const FETCH_CREATE_LEAD_SUCCESS = "FETCH_CREATE_LEAD_SUCCESS";
export const FETCH_CREATE_LEAD_FAILURE = "FETCH_CREATE_LEAD_FAILURE";

export const FETCH_THIS_WEEK_LEADS = "FETCH_THIS_WEEK_LEADS";
export const FETCH_THIS_WEEK_LEADS_SUCCESS = "FETCH_THIS_WEEK_LEADS_SUCCESS";
export const FETCH_THIS_WEEK_LEADS_FAILURE = "FETCH_THIS_WEEK_LEADS_FAILURE";

export const FETCH_THIS_WEEK_LEADS_BUSINESS = "FETCH_THIS_WEEK_LEADS_BUSINESS";
export const FETCH_THIS_WEEK_LEADS_SUCCESS_BUSINESS = "FETCH_THIS_WEEK_LEADS_SUCCESS_BUSINESS";
export const FETCH_THIS_WEEK_LEADS_FAILURE_BUSINESS = "FETCH_THIS_WEEK_LEADS_FAILURE_BUSINESS";

export const FETCH_GET_ALL_ADMIN_LEADS = "FETCH_GET_ALL_ADMIN_LEADS";
export const FETCH_GET_ALL_ADMIN_LEADS_SUCCESS = "FETCH_GET_ALL_ADMIN_LEADS_SUCCESS";
export const FETCH_GET_ALL_ADMIN_LEADS_FAILURE = "FETCH_GET_ALL_ADMIN_LEADS_FAILURE";

export const FETCH_GET_ALL_LEADS = "FETCH_GET_ALL_LEADS";
export const FETCH_GET_ALL_LEADS_SUCCESS = "FETCH_GET_ALL_LEADS_SUCCESS";
export const FETCH_GET_ALL_LEADS_FAILURE = "FETCH_GET_ALL_LEADS_FAILURE";

export const FETCH_GET_ALL_ADMIN_TXNS = "FETCH_GET_ALL_ADMIN_TXNS";
export const FETCH_GET_ALL_ADMIN_TXNS_SUCCESS = "FETCH_GET_ALL_ADMIN_TXNS_SUCCESS";
export const FETCH_GET_ALL_ADMIN_TXNS_FAILURE = "FETCH_GET_ALL_ADMIN_TXNS_FAILURE";

export const FETCH_GET_ALL_TXNS = "FETCH_GET_ALL_TXNS";
export const FETCH_GET_ALL_TXNS_SUCCESS = "FETCH_GET_ALL_TXNS_SUCCESS";
export const FETCH_GET_ALL_TXNS_FAILURE = "FETCH_GET_ALL_TXNS_FAILURE";

export const FETCH_GET_WALLET_AMOUNT = "FETCH_GET_WALLET_AMOUNT";
export const FETCH_GET_WALLET_AMOUNT_SUCCESS = "FETCH_GET_WALLET_AMOUNT_SUCCESS";
export const FETCH_GET_WALLET_AMOUNT_FAILURE = "FETCH_GET_WALLET_AMOUNT_FAILURE";

export const FETCH_THIS_WEEK_PAYMENTS = "FETCH_THIS_WEEK_PAYMENTS";
export const FETCH_THIS_WEEK_PAYMENTS_SUCCESS = "FETCH_THIS_WEEK_PAYMENTS_SUCCESS";
export const FETCH_THIS_WEEK_PAYMENTS_FAILURE = "FETCH_THIS_WEEK_PAYMENTS_FAILURE";

export const FETCH_THIS_WEEK_BUSINESS ="FETCH_THIS_WEEK_BUSINESS";
export const FETCH_THIS_WEEK_BUSINESS_SUCCESS ="FETCH_THIS_WEEK_BUSINESS_SUCCESS";
export const FETCH_THIS_WEEK_BUSINESS_FAILURE ="FETCH_THIS_WEEK_BUSINESS_FAILURE";

export const FETCH_GET_ALL_BUSINESS = "FETCH_GET_ALL_BUSINESS";
export const FETCH_GET_ALL_BUSINESS_SUCCESS = "FETCH_GET_ALL_BUSINESS_SUCCESS";
export const FETCH_GET_ALL_BUSINESS_FAILURE = "FETCH_GET_ALL_BUSINESS_FAILURE";

export const FETCH_SUBMIT_DUPLICATE = "FETCH_SUBMIT_DUPLICATE";
export const FETCH_SUBMIT_DUPLICATE_SUCCESS = "FETCH_SUBMIT_DUPLICATE_SUCCESS";
export const FETCH_SUBMIT_DUPLICATE_FAILURE = "FETCH_SUBMIT_DUPLICATE_FAILURE";

export const FETCH_GET_DUPLICATE_TICKETS = "FETCH_GET_DUPLICATE_TICKETS";
export const FETCH_GET_DUPLICATE_TICKETS_SUCCESS = "FETCH_GET_DUPLICATE_TICKETS_SUCCESS";
export const FETCH_GET_DUPLICATE_TICKETS_FAILURE = "FETCH_GET_DUPLICATE_TICKETS_FAILURE";