import { LogoutOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BarChartOutlined,
  UsergroupAddOutlined,
  DollarOutlined,
  WalletOutlined,
  TruckOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { Link, Outlet, useLocation } from 'react-router-dom';
import GetLeadsModal from '../Modals/GetLeadsModal';
import { useNavigate } from 'react-router-dom'; // Import the useHistory hook
import { connect } from 'react-redux';
import ManualLeadsModal from '../Modals/ManualLeadsModal';
import { getWalletAmount } from '../../store/action/business/TxnAction';
import { setUser } from '../../store/action/common/authAction';

const { Header, Sider, Content } = Layout;

const SiderDemo = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [leadsModalVisible, setLeadsModalVisible] = useState(false);
  const [walletAmount, setWalletAmount] = useState(0);

  useEffect(() => {
    props.getWalletAmount_();
  }, []);

  useEffect(() => {
    if (props.user.role === 'business' && props.wallet && props.wallet.response && props.wallet.response.walletAmount) {
      setWalletAmount(props.wallet.response.walletAmount);
    }
  }, [props.wallet]);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const openModal = () => {
    setModalVisible(true); // Set the modal visibility to true when the button is clicked
  };
  const closeModal = () => {
    setModalVisible(false); // Set the modal visibility to true when the button is clicked
  };

  const openLeadsModal = () => {
    setLeadsModalVisible(true); // Set the modal visibility to true when the button is clicked
  };
  const closeLeadsModal = () => {
    setLeadsModalVisible(false); // Set the modal visibility to true when the button is clicked
  };

  const logout = () => {
    localStorage.clear();
    props.setUser_(null);
    props.clearLoginResponse_();
    window.location.href = '/login';
  };

  const getButtons = () => {
    if (props.user && props.user.role === 'admin') {
      return (
        <Button type="light" style={{ color: 'black', backgroundColor: 'white', borderColor: '#1890ff' }} onClick={openLeadsModal}>Add Leads</Button>
      )
    }
    else if (props.user && props.user.role === 'business') {
      return (
        <React.Fragment>
          <Button type="primary" style={{ color: 'white', marginRight: '16px', backgroundColor: '#1890ff', borderColor: '#1890ff' }} onClick={openModal}>Get More Leads</Button>
          <Button type="light" style={{ color: 'black', backgroundColor: 'white', borderColor: '#1890ff' }} onClick={() => { navigate('/transaction-history'); }}>
            <WalletOutlined style={{ marginRight: '1px' }} />
            <span style={{}}>Wallet: {walletAmount} CA$</span>
          </Button>
        </React.Fragment>
      )
    }
  }
  return (
    <Layout style={{ minHeight: '100vh', width: '100vw' }}>
      <Header className="site-layout-background" style={{ padding: 0, display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
          <div className="logo" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', color: 'white' }}>
          {getButtons()}
          <Button type="light" style={{ color: 'white', backgroundColor: 'red', borderColor: '#1890ff', marginLeft: '16px' }} onClick={logout}>
            <LogoutOutlined style={{ marginRight: '1px' }} />
            <span style={{}}>Logout</span>
          </Button>        </div>
      </Header>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}  >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
            key={location.pathname}
            items={[
              {
                key: props.user.role === "admin" ? '/admin' : '/',
                icon: <BarChartOutlined />,
                label: (
                  <Link to={props.user.role === 'admin' ? "/admin" : "/"}>Dashboard</Link>
                ),
              },
              {
                key: '/my-leads',
                icon: <UsergroupAddOutlined />,
                label: (
                  <Link to="/my-leads">My Leads</Link>
                ),
              },
              {
                key: '/transaction-history',
                icon: <DollarOutlined />,
                label: (
                  <Link to="/transaction-history">Transaction History</Link>
                ),
              },
              (props.user && props.user.role === 'admin' &&
              {
                key: '/businesses',
                icon: <TruckOutlined />,
                label: (
                  <Link to="/admin/businesses">Businesses</Link>
                ),
              }),
              (props.user && props.user.role === 'admin' &&
              {
                key: '/tickets',
                icon: <ProfileOutlined />,
                label: (
                  <Link to="/admin/tickets">Tickets</Link>
                ),
              }
              )
            ]}
          />
        </Sider>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            overflowY: 'auto'
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <GetLeadsModal visible={modalVisible} closeModal={closeModal} />
      <ManualLeadsModal visible={leadsModalVisible} closeModal={closeLeadsModal} />
    </Layout>
  );
};

const mapStateToProps = state => ({
  user: state.user.response,
  wallet: state.wallet
})

const mapDispatchToProps = dispatch => ({
  getWalletAmount_: () => dispatch(getWalletAmount()),
  setUser_: (data) => {
    dispatch(setUser(data));
  },
  clearLoginResponse_: () => {
      dispatch({ type: 'FETCH_LOGIN_CLEAR' });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiderDemo);
