import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { createCampaignApi } from '../../api/business/CampaignApi';

function* fetchCreateCampaignSaga(action) {
    try {
       const data = yield call(createCampaignApi, action.payload);
       yield put({type: type.FETCH_CREATE_CAMPAIGN_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_CREATE_CAMPAIGN_FAILURE, message: e.message});
    }
}
 


export function* watchFetchCreateCampaignSaga() {
    yield takeEvery(type.FETCH_CREATE_CAMPAIGN, fetchCreateCampaignSaga);
}
 