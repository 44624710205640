import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { getAllTxnAdminApi } from '../../api/admin/LeadAdminApi';

function* fetchAllTxnAdminSaga(action) {
    try {
       const data = yield call(getAllTxnAdminApi);
       yield put({type: type.FETCH_GET_ALL_ADMIN_TXNS_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_GET_ALL_ADMIN_TXNS_FAILURE, message: e.message});
    }
}
 


export function* watchFetchAllTxnAdminSaga() {
    yield takeEvery(type.FETCH_GET_ALL_ADMIN_TXNS, fetchAllTxnAdminSaga);
}
 