import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { getAllAdminCardApi, getThisWeekBusinessAdminApi, getThisWeekPaymentsAdminApi } from '../../api/admin/dashboardApi';

function* fetchAdminCardsSaga(action) {
    try {
       const cardData = yield call(getAllAdminCardApi);
       yield put({type: type.FETCH_ALL_ADMIN_CARDS_SUCCESS, cardData});
    } catch (e) {
       yield put({type: type.FETCH_ALL_ADMIN_CARDS_FAILURE, message: e.message});
    }
}

function* fetchThisWeekBusinessSaga(action) {
    try {
       const data = yield call(getThisWeekBusinessAdminApi);
       yield put({type: type.FETCH_THIS_WEEK_BUSINESS_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_THIS_WEEK_BUSINESS_FAILURE, message: e.message});
    }
}

function* fetchThisWeekpaymentsSaga(action) {
    try {
       const data = yield call(getThisWeekPaymentsAdminApi);
       yield put({type: type.FETCH_THIS_WEEK_PAYMENTS_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_THIS_WEEK_PAYMENTS_FAILURE, message: e.message});
    }
}
 


export function* watchFetchAdminCardsSaga() {
    yield takeEvery(type.FETCH_ALL_ADMIN_CARDS, fetchAdminCardsSaga);
}
export function* watchFetchThisWeekBusinessSaga() {
    yield takeEvery(type.FETCH_THIS_WEEK_PAYMENTS, fetchThisWeekBusinessSaga);
}
export function* watchFetchThisWeekpaymentsSaga() {
    yield takeEvery(type.FETCH_THIS_WEEK_PAYMENTS, fetchThisWeekpaymentsSaga);
}
 