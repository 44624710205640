import { all } from 'redux-saga/effects'
import { watchFetchBusinessCardsSaga } from './business/DashboardSaga'
import { watchFetchLoginSaga } from './common/AuthSaga'
import { watchFetchCreateCampaignSaga } from './business/CampaignSaga'
import { watchFetchAdminCardsSaga, watchFetchThisWeekBusinessSaga, watchFetchThisWeekpaymentsSaga } from './admin/DashboardSaga'
import { watchFetchAllLeadsAdminSaga, watchFetchCreateLeadSaga, watchFetchThisWeekLeadsSaga } from './admin/LeadAdminSaga'
import { watchFetchAllLeadsSaga, watchFetchAllThisWeekLeadsBusinessSaga, watchFetchAllTicketsSaga, watchFetchSubmitDuplicateSaga } from './business/LeadSaga'
import { watchFetchAllTxnAdminSaga } from './admin/TxnAdminSaga'
import { watchFetchAllTxnSaga, watchFetchWalletSaga } from './business/TxnSaga'
import { watchFetchGetBusinessSaga } from './admin/BusinessAdminSaga'

export default function* rootSaga() {
    yield all([
        watchFetchBusinessCardsSaga(),
        watchFetchLoginSaga(),
        watchFetchCreateCampaignSaga(),
        watchFetchAdminCardsSaga(),
        watchFetchCreateLeadSaga(),
        watchFetchThisWeekLeadsSaga(),
        watchFetchAllLeadsSaga(),
        watchFetchAllLeadsAdminSaga(),
        watchFetchAllTxnAdminSaga(),
        watchFetchAllTxnSaga(),
        watchFetchWalletSaga(),
        watchFetchAllThisWeekLeadsBusinessSaga(),
        watchFetchThisWeekBusinessSaga(),
        watchFetchThisWeekpaymentsSaga(),
        watchFetchGetBusinessSaga(),
        watchFetchSubmitDuplicateSaga(),
        watchFetchAllTicketsSaga(),
    ])
}