import { combineReducers } from "redux";
import businessCardReducer from "./business/dashboardReducer";
import {loginReducer, userReducer} from "./common/authReducer";
import createCampaignReducer from "./business/CampaignReducer";
import adminCardReducer, { thisWeekBusinessReducer, thisWeekPaymentsReducer } from "./admin/dashboardReducer";
import {allLeadsAdminReducer, createLeadReducer, thisWeekLeadsReducer} from "./admin/LeadAdminReducer";
import { allLeadsReducer, getDuplicateReducer, submitduplicateReducer, thisWeekLeadBusinessReducer } from "./business/LeadReducer";
import { allTxnAdminReducer } from "./admin/TxnAdminReducer";
import { allTxnReducer, getWalletReducer } from "./business/TxnReducer";
import { getBusinessReducer } from "./admin/BusinessAdminReducer";
// import posts from "./postReducer";

const rootReducer = combineReducers({
    businesscard:  businessCardReducer,
    admincard:  adminCardReducer,
    login:  loginReducer, // Maps the `login` key to `loginReducer`
    user: userReducer,
    createCampaign:createCampaignReducer,
    createLead: createLeadReducer,
    thisWeekLeads: thisWeekLeadsReducer,
    allLeadsAdmin: allLeadsAdminReducer,
    allLeads: allLeadsReducer,
    allTxnAdmin: allTxnAdminReducer,
    allTxn: allTxnReducer,
    wallet: getWalletReducer,
    thisWeekLeadBusiness: thisWeekLeadBusinessReducer,
    thisWeekBusiness: thisWeekBusinessReducer,
    thisWeekPayments: thisWeekPaymentsReducer,
    businessList: getBusinessReducer,
    submitduplicate: submitduplicateReducer,
    ticketList: getDuplicateReducer,
});

export default rootReducer;
