import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { createLeadApi, getAllLeadsAdminApi, getThisWeekLeadsAdminApi } from '../../api/admin/LeadAdminApi';

function* fetchCreateLeadSaga(action) {
    try {
       const data = yield call(createLeadApi, action.payload);
       yield put({type: type.FETCH_CREATE_LEAD_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_CREATE_LEAD_FAILURE, message: e.message});
    }
}
function* fetchThisWeekLeadsSaga(action) {
    try {
       const data = yield call(getThisWeekLeadsAdminApi);
       yield put({type: type.FETCH_THIS_WEEK_LEADS_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_THIS_WEEK_LEADS_FAILURE, message: e.message});
    }
}
function* fetchAllLeadsAdminSaga(action) {
    try {
       const data = yield call(getAllLeadsAdminApi);
       yield put({type: type.FETCH_GET_ALL_ADMIN_LEADS_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_GET_ALL_ADMIN_LEADS_FAILURE, message: e.message});
    }
}
 


export function* watchFetchCreateLeadSaga() {
    yield takeEvery(type.FETCH_CREATE_LEAD, fetchCreateLeadSaga);
}
export function* watchFetchThisWeekLeadsSaga() {
    yield takeEvery(type.FETCH_THIS_WEEK_LEADS, fetchThisWeekLeadsSaga);
}
export function* watchFetchAllLeadsAdminSaga() {
    yield takeEvery(type.FETCH_GET_ALL_ADMIN_LEADS, fetchAllLeadsAdminSaga);
}
 