import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Typography } from 'antd';
import LeadsTable from '../components/Leads/LeadsTable';
import { connect } from 'react-redux';
import { getAllBusinessCards } from '../store/action/business/DashboardActions';
import { getThisWeekLeadBusiness } from '../store/action/business/LeadAction';

const LeadGenerationAnalytics = (props) => {
  const { Title, Text } = Typography;
  const [totalAmt, setTotalAmt] = useState(10);
  const [totalLeads, setTotalLeads] = useState(10);
  const [leads, setLeads] = useState([]);
  
  useEffect(() => {
    props.getAllBusinessCards_();
    props.getThisWeekLeads_();
  }, []);

  useEffect(() => {
    setTotalAmt(props.businessCard.totalAmount);
    setTotalLeads(props.businessCard.totalDistributedLeads);
  }, [props.businessCard]);

  useEffect(() => {
    if(props.thisWeekLeadBusiness && props.thisWeekLeadBusiness.leads && props.thisWeekLeadBusiness.leads.length > 0)
      setLeads(props.thisWeekLeadBusiness.leads);
  }, [props.thisWeekLeadBusiness]);

  return (
    <div style={{ padding: 20 }}>
      <Title level={2}>Dashboard</Title>
      <Row gutter={20} style={{ width: '100%' }}>
        <Col span={8}>
          <Card
            style={{
              height: '100%',
              backgroundColor: '#4CAF50',
              borderRadius: 10,
            }}
          >
            <Statistic
              value={totalAmt}
              prefix="$"
              suffix="CAD"
              style={{ color: '#fff' }}
            />
            <Text type="secondary" style={{ color: '#fff' }}>
              Total Money Spent
            </Text>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{height: '100%',backgroundColor: '#2196F3',borderRadius: 10}}>
            <Statistic value={totalLeads} suffix="Leads" style={{ color: '#fff' }}/>
            <Text type="secondary" style={{ color: '#fff' }}>
              Total Leads Generated
            </Text>
          </Card>
        </Col>

     {/* <Col span={6}>
          <Card
            style={{height: '100%',backgroundColor: '#9C27B0',borderRadius: 10,}}>
            <Statistic value={2000} prefix="$" suffix="CAD" style={{color: '#fff'}}/>
            <Text type="secondary" style={{ color: '#fff' }}>
              Assumed Minimum Revenue
            </Text>
          </Card>
        </Col> */}

        <Col span={8}>
          <Card style={{ height: '100%', backgroundColor: 'orange', borderRadius: 10,}}>
            <Statistic value={5000} prefix="$" suffix="Avg Spend" style={{ color: '#fff' }}/>
            <Text type="secondary" style={{ color: '#fff' }}>
              Average Amount Others Spent Similar to You
            </Text>
          </Card>
        </Col>
      </Row>

      <Row gutter={20} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Card title="This Week's Leads" >
            <LeadsTable leads={leads} mini={true} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = state => ({
  businessCard: state.businesscard.response,
  thisWeekLeadBusiness: state.thisWeekLeadBusiness.response
})

const mapDispatchToProps = dispatch => ({
  getAllBusinessCards_: ()=>{
      dispatch(getAllBusinessCards());
  },
  getThisWeekLeads_: ()=>{
    dispatch(getThisWeekLeadBusiness());
  }
}) 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadGenerationAnalytics);