import { Table, Button, Row, Col } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import RaiseDuplicateModal from '../Modals/RaiseDuplicateModal';
import moment from 'moment';
import { connect } from 'react-redux';
import { submitDuplicate } from '../../store/action/business/LeadAction';

const LeadsTable = (props) => {

    const [recordId, setRecordId] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const columns = [
        {
            title: 'Registered Date',
            dataIndex: 'createdAt',
            key: 'leadId',
            render: (regdate) => moment(regdate).format('YYYY-MM-DD'),
        },
        {...(!props.mini && {
            title: 'Lead Id',
            dataIndex: 'leadId',
            key: 'leadId',
        })},
        {
            title: 'Move on Date',
            dataIndex: ['leadData', 'movingDate'],
            key: 'leadId',
            render: (movedate) => moment(movedate).format('YYYY-MM-DD'),
        },
        {
            title: 'Name',
            dataIndex: ['leadData', 'name'],
            key: 'leadId',
        },
        {
            title: 'Phone',
            dataIndex: ['leadData', 'phone'],
            key: 'leadId',
        },
        {
            title: 'Moving From',
            dataIndex: ['leadData', 'movingFrom'],
            key: 'leadId',
        },
        {
            title: 'Moving To',
            dataIndex: ['leadData', 'movingTo'],
            key: 'leadId',
        },
    ];
    const RaiseDuplicate = (record) => {
        setRecordId(record.leadId);
        setModalVisible(true);
    }
    const submitDuplicate = (refLeadId, issueDetail) => {
        setModalVisible(false);
        const data = {
            leadId: recordId,
            refLeadId: refLeadId,
            issueDetail: issueDetail
        }
        props.submitDuplicate_(data);
    }
    return (
        <div>
            <Table
                columns={columns}
                dataSource={props.leads}
                pagination={!props.mini}
                rowKey={"leadId"}
                expandable={props.expand ? {
                    expandedRowRender: (record) => (
                        <>
                            <Row>
                                <Col span={4}> Available time to call:</Col>
                                <Col span={6}>{record.leadData.timeToCall}</Col>
                                <Col span={2}>Email:</Col>
                                <Col span={12}>{record.leadData.email}</Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col span={4}> Property Type:</Col>
                                <Col span={6}>{record.leadData.propertyType}</Col>
                                <Col span={3}> Property Size:</Col>
                                <Col span={6}>{record.leadData.propertySize}</Col>
                            </Row>
                            <p style={{ float: "right" }}>

                                <Button type="link" danger onClick={() => RaiseDuplicate(record)
                                }>Raise Duplicate</Button>
                            </p>
                        </>

                    ),
                } : false}
                footer={() => props.mini && props.leads.length > 0 ? (
                    <tr>
                        <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                            <Link to="/my-leads">View More</Link>
                        </td>
                    </tr>
                ) : null}
            />
            <RaiseDuplicateModal refId={recordId} visible={modalVisible} onOk={submitDuplicate} onCancel={() => setModalVisible(false)} key={recordId} />
        </div>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    submitDuplicate_: (data) => {
        dispatch(submitDuplicate(data));
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeadsTable);

