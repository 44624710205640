import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { createLeadApi, getAllLeadsAdminApi, getThisWeekLeadsAdminApi } from '../../api/admin/LeadAdminApi';
import { getAllBusinessApi } from '../../api/admin/BusniessAdminApi';

function* fetchGetBusinessSaga(action) {
    try {
       const data = yield call(getAllBusinessApi, action.payload);
       yield put({type: type.FETCH_GET_ALL_BUSINESS_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_GET_ALL_BUSINESS_FAILURE, message: e.message});
    }
}
 


export function* watchFetchGetBusinessSaga() {
    yield takeEvery(type.FETCH_GET_ALL_BUSINESS, fetchGetBusinessSaga);
}