import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { loginApi } from '../../api/common/authApi';

function* fetchLoginSaga(action) {
    try {
       const data = yield call(loginApi, action.payload);
       yield put({type: type.FETCH_LOGIN_SUCCESS, data});
    } catch (e) {
       yield put({type: type.FETCH_ALL_BUSINESS_CARDS_FAILURE, message: e.message});
    }
}
 


export function* watchFetchLoginSaga() {
    yield takeEvery(type.FETCH_LOGIN, fetchLoginSaga);
}
 