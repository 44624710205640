import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Typography } from 'antd';
import LeadsTable from '../components/Leads/LeadsTable';
import { connect } from 'react-redux';
import { getAllAdminCards, getThisWeekBusiness, getThisWeekLeads, getThisWeekPayments } from '../store/action/admin/DashboardActions';
import BusinessesTable from '../components/Businesses/EnrolledBusinessesTable';
import ComparisonChart from '../components/Charts/ComparisionChart';

const AdminDashboard = (props) => {
  const { Title, Text } = Typography;
  const [businesses, setBusinesses] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState([]);
  const [totalDistributedLeads, setTotalDistributedLeads] = React.useState([]);
  const [paidBusinesses, setPaidBusinesses] = React.useState([]);
  const [leads, setLeads] = useState([]);
  const [payments, setPayments] = useState([]);
  const [businessesList, setBusinessesList] = useState([]);

  useEffect(() => {
    if (!props.user || props.user.role !== 'admin') {
      if (localStorage.getItem('role') && localStorage.getItem('role') !== 'admin') {
        window.location.href = '/';
      }
    }
    props.getAllAdminCards_();
    props.getThisWeekLeads_();
    props.getThisWeekPayments_();
    props.getThisWeekBusiness_();
  }, []);

  useEffect(() => {
    if (props.adminCard.totalAmount) {
      setTotalAmount(props.adminCard.totalAmount);
    }
    if (props.adminCard.totalDistributedLeads) {
      setTotalDistributedLeads(props.adminCard.totalDistributedLeads);
    }
    if (props.adminCard.paidBusinesses) {
      setPaidBusinesses(props.adminCard.paidBusinesses);
    }
    if (props.adminCard.businesses) {
      setBusinesses(props.adminCard.businesses);
    }
  }, [props.adminCard]);

  useEffect(() => {
    if (props.thisWeekLeads && props.thisWeekLeads.leads) {
      if (props.thisWeekLeads.leads.length > 0) {
        setLeads(props.thisWeekLeads.leads);
      } else {
        setLeads([]);
      }
    }
  }, [props.thisWeekLeads]);

  useEffect(() => {
    if (props.thisWeekPayments && props.thisWeekPayments.payments) {
      if (props.thisWeekPayments.payments.length > 0) {
        setPayments(props.thisWeekPayments.payments);
      } else {
        setPayments([]);
      }
    }
  }, [props.thisWeekPayments]);

  useEffect(() => {
    if (props.thisWeekBusiness && props.thisWeekBusiness.businesses) {
      if (props.thisWeekBusiness.businesses.length > 0) {
        setBusinessesList(props.thisWeekBusiness.businesses);
      } else {
        setBusinessesList([]);
      }
    }
  }, [props.thisWeekBusiness]);

  return (
    <div style={{ padding: 20 }}>
      <Title level={2}>Dashboard</Title>
      <Row gutter={1} style={{ width: '100%' }}>
        <Col span={6}>
          <Card
            style={{
              backgroundColor: '#4CAF50',
              borderRadius: 10,
            }}
          >
            <Statistic
              value={totalAmount}
              prefix="$"
              suffix="CAD"
              style={{ color: '#fff' }}
            />
            <Text type="secondary" style={{ color: '#fff' }}>
              Total Money Recieved
            </Text>
          </Card>
          <Card
            style={{
              backgroundColor: '#2196F3',
              borderRadius: 10,
            }}
          >
            <Statistic
              value={totalDistributedLeads}
              suffix="Leads"
              style={{ color: '#fff' }}
            />
            <Text type="secondary" style={{ color: '#fff' }}>
              Total Leads Generated
            </Text>
          </Card>
          <Card
            style={{
              backgroundColor: '#9C27B0',
              borderRadius: 10,
            }}
          >
            <Statistic
              value={businesses}
              style={{ color: '#fff' }}
            />
            <Text type="secondary" style={{ color: '#fff' }}>
              Businesses Enrolled
            </Text>
          </Card>
          <Card
            style={{
              backgroundColor: 'orange',
              borderRadius: 10,
            }}
          >
            <Statistic
              value={paidBusinesses}
              style={{ color: '#fff' }}
            />
            <Text type="secondary" style={{ color: '#fff' }}>
              Businesses Paid to You
            </Text>
          </Card>
        </Col>
        <Col span={17} offset={1}>
          <Card
              style={{
                height: '100%',
                borderRadius: 10,
              }}
            >
              <ComparisonChart />
            </Card>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginTop: 20 }}>
        <Col span={24}>
          {/* <Card title="This Week's Payments" >
            <LeadsTable leads={leads} mini={true} />
          </Card> */}
          <Card title="This Week's Leads" >
            <LeadsTable leads={leads} mini={true} />
          </Card>
          <Card title="This Week's Businesses Enrolled" >
            <BusinessesTable data={businessesList} mini={true} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};


const mapStateToProps = state => ({
  user: state.user.response,
  adminCard: state.admincard.response,
  thisWeekLeads: state.thisWeekLeads.response,
  thisWeekBusiness: state.thisWeekBusiness.response,
  thisWeekPayments: state.thisWeekPayments.response,
})

const mapDispatchToProps = dispatch => ({
  getAllAdminCards_: () => {
    dispatch(getAllAdminCards());
  },
  getThisWeekLeads_: () => {
    dispatch(getThisWeekLeads());
  },
  getThisWeekPayments_: () => {
    dispatch(getThisWeekPayments());
  },
  getThisWeekBusiness_: () => {
    dispatch(getThisWeekBusiness());
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboard);