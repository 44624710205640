import * as type from '../../types';

const initialCreateCampaignState = {
    response: {},
    loading: false,
    error: null
}

export default function createCampaignReducer(state = initialCreateCampaignState, action) {
    switch (action.type) {
        case type.FETCH_CREATE_CAMPAIGN:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_CREATE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data
            }
        case type.FETCH_CREATE_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
