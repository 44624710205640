import * as type from '../../types';

const initialCreateLeadState = {
    response: {},
    loading: false,
    error: null
}
const initialThisWeekLeadState = {
    response: {},
    loading: false,
    error: null
}
const initialAllLeadAdminState = {
    response: {},
    loading: false,
    error: null
}

export function createLeadReducer(state = initialCreateLeadState, action) {
    switch (action.type) {
        case type.FETCH_CREATE_LEAD:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_CREATE_LEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data
            }
        case type.FETCH_CREATE_LEAD_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}

export function thisWeekLeadsReducer(state = initialThisWeekLeadState, action) {
    switch (action.type) {
        case type.FETCH_THIS_WEEK_LEADS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_THIS_WEEK_LEADS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_THIS_WEEK_LEADS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}

export function allLeadsAdminReducer(state = initialAllLeadAdminState, action) {

    switch (action.type) {
        case type.FETCH_GET_ALL_ADMIN_LEADS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_GET_ALL_ADMIN_LEADS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_GET_ALL_ADMIN_LEADS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
