import * as type from '../../types';

const initialAllTxnAdminState = {
    response: {},
    loading: false,
    error: null
}

export function allTxnAdminReducer(state = initialAllTxnAdminState, action) {

    switch (action.type) {
        case type.FETCH_GET_ALL_ADMIN_TXNS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_GET_ALL_ADMIN_TXNS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_GET_ALL_ADMIN_TXNS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
