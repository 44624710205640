import * as type from '../../types';

const initialAllLeadState = {
    response: {},
    loading: false,
    error: null
}
const initialThisWeekLeadsBusinessState = {
    response: {},
    loading: false,
    error: null
}
const initialSubmitDuplicateState = {
    response: {},
    loading: false,
    error: null
}
const initialGetDuplicateState = {
    response: {},
    loading: false,
    error: null
}

export function allLeadsReducer(state = initialAllLeadState, action) {
    switch (action.type) {
        case type.FETCH_GET_ALL_LEADS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_GET_ALL_LEADS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_GET_ALL_LEADS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
export function thisWeekLeadBusinessReducer(state = initialThisWeekLeadsBusinessState, action) {
    switch (action.type) {
        case type.FETCH_THIS_WEEK_LEADS_BUSINESS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_THIS_WEEK_LEADS_SUCCESS_BUSINESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_THIS_WEEK_LEADS_FAILURE_BUSINESS:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
export function submitduplicateReducer(state = initialSubmitDuplicateState, action) {
    switch (action.type) {
        case type.FETCH_SUBMIT_DUPLICATE:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_SUBMIT_DUPLICATE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_SUBMIT_DUPLICATE_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
export function getDuplicateReducer(state = initialGetDuplicateState, action) {
    switch (action.type) {
        case type.FETCH_GET_DUPLICATE_TICKETS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_GET_DUPLICATE_TICKETS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_GET_DUPLICATE_TICKETS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
