import { Card, Col, Row, Table } from "antd";
import Title from "antd/es/typography/Title";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDuplicateTickets } from "../store/action/admin/LeadAdminAction";
import { render } from "@testing-library/react";

const Tickets = (props) => {
    const [tickets, setTickets] = useState([]);

    const columns = [
        {
            title: 'lead Id',
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: 'Registered Date',
            dataIndex: ['leadDetails', 'createdAt'],
            key: '_id',
            render: (date) => moment(date).format('YYYY-MM-DD'),
        },
        {
            title: 'Lead Name',
            dataIndex: ['leadDetails', 'leadData', 'name'],
            key: '_id',
        },
        {
            title: 'Source',
            dataIndex: ['leadDetails', 'source'],
            key: '_id',
        },
        {
            title: 'Province',
            render: ()=>"Alberta",
            key: '_id',
        },
    ];

    const ticketcolumns = [
        {
            title: 'Business Id',
            dataIndex: 'userId',
            key: '_id',
        },
        {
            title: 'Business Name',
            dataIndex: ['userDetails', 'companyName'],
            key: '_id',
        },
        {
            title: 'Issue date',
            dataIndex: 'issuedate',
            key: '_id',
            render: (date) => moment(date).format('YYYY-MM-DD'),
        },
        {
            title: 'Lead Reference Id',
            dataIndex: 'refLeadId',
            key: '_id',
        },
        {
            title: 'Issue Detail',
            dataIndex: 'issueDetail',
            key: '_id',
        },
    ];
    
    useEffect(() => {
        props.getDuplicateTickets_();
    }, []);

    useEffect(() => {
        if (props.ticketList && props.ticketList.ticket) {
            if (props.ticketList.ticket.length > 0) {
                console.log('props.ticketList.ticket', props.ticketList.ticket);
                setTickets(props.ticketList.ticket);
            } else {
                setTickets([]);
            }
        }
    });

    return (
        <div style={{ padding: 20 }}>
            <Title level={2}>Raised Tickets</Title>
            <Row gutter={20} style={{ marginTop: 20 }}>
                <Col span={24}>
                    <Card style={{ height: '100%' }}>
                        <Table
                            columns={columns}
                            dataSource={tickets}
                            pagination={true}
                            rowKey={(record) => record._id}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <>
                                        <Table
                                            columns={ticketcolumns}
                                            dataSource={record.tickets}
                                        />
                                    </>
                                ),
                            }}

                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}


const mapStateToProps = state => ({
  user: state.user.response,
  ticketList: state.ticketList.response,
})

const mapDispatchToProps = dispatch => ({
    getDuplicateTickets_: () => {
        dispatch(getDuplicateTickets());
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tickets);