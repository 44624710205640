import * as type from '../../types';

const initialBusinessListState = {
    response: {},
    loading: false,
    error: null
}

export function getBusinessReducer(state = initialBusinessListState, action) {
    switch (action.type) {
        case type.FETCH_GET_ALL_BUSINESS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_GET_ALL_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_GET_ALL_BUSINESS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}