import axios from "axios";
import { LOGIN_URL } from "../../constants/helper";
import { NO_TOKEN_URL } from "../../constants/helper";

// const axios_config = axios.create({
//     baseURL: `${window.location.protocol}//${window.location.hostname}`
// })


const axios_config = axios.create({
    // baseURL: `http://43.204.96.125:3001/`
    baseURL: `https://verse.blaccdot.com/ldsbackend/`
})


const redirect_to_login = () => {
    window.location.href = LOGIN_URL;
}

axios_config.interceptors.request.use(function(config) {
    if(NO_TOKEN_URL.includes(config.url.split("/")[config.url.split("/").length - 1])) {
        return config;
    }
    const token = localStorage.getItem("accessToken");
    if(!token) {
        redirect_to_login();
    }
    config.headers.Authorization = `${token}`;
    return config;
   })

    axios_config.interceptors.response.use(function(response) {
        return response;
    },
    function(error) {
     if(error.response && error.response === 401) {
      redirect_to_login();
  }
})

export default axios_config;