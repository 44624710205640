import jsPDF from 'jspdf';
import 'jspdf-autotable';

function downloadInvoice(invoiceData) {
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(18);
    doc.text('Invoice', 14, 22);

    // Add invoice details
    doc.setFontSize(12);
    doc.text(`Invoice Number: ${invoiceData.number}`, 14, 32);
    doc.text(`Date: ${invoiceData.date}`, 14, 40);
    doc.text(`Customer Name: ${invoiceData.customerName}`, 14, 48);
    // doc.text(`Customer Address: ${invoiceData.customerAddress}`, 14, 56);

    // Add table of items
    const tableColumn = ["Item", "Quantity", "Price", "Total"];
    const tableRows = [];

    invoiceData.items.forEach(item => {
        const itemData = [
            item.name,
            item.quantity,
            item.price,
            (item.quantity * item.price).toFixed(2)
        ];
        tableRows.push(itemData);
    });
    const tax = ((invoiceData.items[0].quantity * invoiceData.items[0].price).toFixed(2) * 0.18.toFixed(2));
    tableRows.push(["Tax@18.00", 1, tax, tax]);

    doc.autoTable(tableColumn, tableRows, { startY: 70 });

    // Add total amount
    const finalY = doc.lastAutoTable.finalY || 70;
    doc.text(`Total: ${((invoiceData.items[0].quantity * invoiceData.items[0].price) + tax).toFixed(2)}`, 14, finalY + 10);

    // Save the PDF
    doc.save(`invoice_${invoiceData.number}.pdf`);
}

export default downloadInvoice;