import * as type from '../../types';

const initialBusinessCardsState = {
    response: {},
    loading: false,
    error: null
}

export default function businessCardReducer(state = initialBusinessCardsState, action) {
    switch (action.type) {
        case type.FETCH_ALL_BUSINESS_CARDS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_ALL_BUSINESS_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.cardData.data
            }
        case type.FETCH_ALL_BUSINESS_CARDS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
