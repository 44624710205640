import React, { useEffect, useState } from 'react';
import { Modal, Select, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { createCampaign } from '../../store/action/business/CampaignAction';
import OpenNotification from '../../utils/OpenNotification';

const { Option } = Select;

const GetLeadsModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [provinceId, setProvinceId] = useState();
  const [longDistanceLeads, setLongDistanceLeads] = useState(false);

  const handleOk = () => {
    setLoading(true);
    // perform Validations

    let data = {
      provinceId,
      amount,
      longDistanceLeads
    };

    props.createCampaign_(data);
  };

  const handleCancel = () => {
    // Perform any necessary cleanup or actions
    setVisible(false);
    props.closeModal()
  };

  useEffect(() => {
    if (props.createCampaign && props.createCampaign.data && props.createCampaign.data.url) {
      const newWindow = window.open(props.createCampaign.data.url, '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        // console.error('Pop-up was blocked, Please Check the Browser.');
      }
      setLoading(false);
      props.closeModal();
    }
    props.closeModal();
  }, [props.createCampaign]);


  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <>
      <Modal
        title="Get Leads"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>
          <span> Amount To Recharge:</span><br />
          <Select
            placeholder="Select Amount to Recharge"
            onChange={(value) => setAmount(value)}
          >
            <Option value="1000">1000 CAD</Option>
            <Option value="1500">1500 CAD</Option>
            <Option value="2000">2000 CAD</Option>
            <Option value="2500">2500 CAD</Option>
            <Option value="3000">3000 CAD</Option>
            <Option value="4000">4000 CAD</Option>
            <Option value="5000">5000 CAD</Option>
          </Select>
        </label>
        <br />
        <br />
        <label>
          <span>Targeted Province:</span><br />
          <Select
            placeholder="Select Province"
            onChange={(value) => setProvinceId(value)}
          >
            <Option value="-1">All</Option>
            <Option value="1">Alberta</Option>
            <Option value="BC">British Columbia</Option>
            <Option value="3">Manitoba</Option>
            <Option value="NB">New Brunswick</Option>
            <Option value="NL">Newfoundland and Labrador</Option>
            <Option value="NS">Nova Scotia</Option>
            <Option value="NT">Northwest Territories</Option>
            <Option value="NU">Nunavut</Option>
            <Option value="2">Ontario</Option>
            <Option value="PE">Prince Edward Island</Option>
            <Option value="QC">Quebec</Option>
            <Option value="SK">Saskatchewan</Option>
            <Option value="YT">Yukon</Option>
          </Select>
        </label>
        <br />
        <br />
        <label>
          <Checkbox
            checked={longDistanceLeads}
            onChange={(e) => setLongDistanceLeads(e.target.checked)}
          >
            Accept long distance leads
          </Checkbox>
        </label>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  createCampaign: state.createCampaign.response
})

const mapDispatchToProps = dispatch => ({
  createCampaign_: (data)=>{
      dispatch(createCampaign(data));
  }
}) 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetLeadsModal);