import * as type from '../../types';

const initialAllTxnState = {
    response: {},
    loading: false,
    error: null
}
const initialWalletState = {
    response: {},
    loading: false,
    error: null
}

export function allTxnReducer(state = initialAllTxnState, action) {

    switch (action.type) {
        case type.FETCH_GET_ALL_TXNS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_GET_ALL_TXNS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_GET_ALL_TXNS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}


export function getWalletReducer(state = initialWalletState, action) {

    switch (action.type) {
        case type.FETCH_GET_WALLET_AMOUNT:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_GET_WALLET_AMOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_GET_WALLET_AMOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}

