import * as type from '../../types';

export function getAllLeads() {
    return {
        type: type.FETCH_GET_ALL_LEADS
    }
}

export function getThisWeekLeadBusiness() {
    return {
        type: type.FETCH_THIS_WEEK_LEADS_BUSINESS
    }
}

export function submitDuplicate(data) {
    return {
        type: type.FETCH_SUBMIT_DUPLICATE,
        data
    }
}