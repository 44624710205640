import './App.css';
import 'antd/dist/reset.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SiderDemo from './components/Layout/Layout';
import { connect, Provider } from "react-redux";
import store from './store/store';
import '@fontsource/lato';
import LeadGenerationAnalytics from './pages/Dashboard';
import TransactionPage from './pages/Transactions';
import MyLeads from './pages/MyLeads';
import RegistrationPage from './pages/Registration';
import AdminDashboard from './pages/AdminDashboard';
import Businesses from './pages/Businesses';
import RedirectMail from './pages/RedirectMail';
import Password from './pages/Password';
import Login from './pages/login';
import { useEffect } from 'react';
import { setUser } from './store/action/common/authAction';
import Tickets from './pages/Tickets';
import { type } from '@testing-library/user-event/dist/type';
import 'bootstrap/dist/css/bootstrap.min.css';


function App(props) {
  useEffect(() => {
    if(!props.user){
      const user = {
        email: JSON.parse(localStorage.getItem('email')),
        name: localStorage.getItem('name'),
        role: localStorage.getItem('role'),
      };
      if (!user.role) {
        localStorage.clear();
        props.setUser_(null);
        props.clearLoginResponse_();
      }
      props.setUser_(user)
    }
  })
  return (
      <Router>
        <Routes>
          <Route element={<SiderDemo />}>
            <Route path="/" element={<LeadGenerationAnalytics />} />
            <Route path="/transaction-history" element={<TransactionPage />} />
            <Route path="/my-leads" element={<MyLeads />} />
            <Route path="/admin">
              <Route index element={<AdminDashboard />} />
              <Route path="businesses" element={<Businesses />} />
              <Route path="tickets" element={<Tickets />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<RegistrationPage />} />
          <Route path="/redirect-mail" element={<RedirectMail />} />
          <Route path="/password" element={<Password />} />

        </Routes>
        <Routes>
        </Routes>
      </Router>
  );
}


const mapStateToProps = state => ({
  user: state.user.resposne
})

const mapDispatchToProps = dispatch => ({
  setUser_: (data)=>{
      dispatch(setUser(data));
  },
  clearLoginResponse_: ()=>{
      dispatch({type: 'FETCH_LOGIN_CLEAR'});
  }
}) 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);