import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../../types';
import { getAllBusinessCardApi } from '../../api/business/dashboardApi';

function* fetchBusinessCardsSaga(action) {
    try {
       const cardData = yield call(getAllBusinessCardApi);
       yield put({type: type.FETCH_ALL_BUSINESS_CARDS_SUCCESS, cardData});
    } catch (e) {
       yield put({type: type.FETCH_ALL_BUSINESS_CARDS_FAILURE, message: e.message});
    }
}
 


export function* watchFetchBusinessCardsSaga() {
    yield takeEvery(type.FETCH_ALL_BUSINESS_CARDS, fetchBusinessCardsSaga);
}
 