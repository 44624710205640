import React, { useEffect, useState } from 'react';
import { Modal, Select, Checkbox, Row, Col, Input, Form, DatePicker, Button } from 'antd';
import { connect } from 'react-redux';
import { createCampaign } from '../../store/action/business/CampaignAction';
import moment from 'moment';
import { createLead } from '../../store/action/admin/LeadAdminAction';
import OpenNotification from '../../utils/OpenNotification';

const { Option } = Select;

const ManualLeadsModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [provinceId, setProvinceId] = useState();
  const [longDistanceLeads, setLongDistanceLeads] = useState(false);

  const handleOk = () => {
    setLoading(true);
    // perform Validations

    let data = {
      provinceId,
      amount,
      longDistanceLeads
    };

    props.createLead_(data);
  };

  useEffect(() => {
    if (props.createLead) {
      if (props.createLead.message === "Leads gathered successfully") {
        OpenNotification("success", "Success", "Lead Created Successfully");
        setVisible(false);
        setLoading(false);
        props.closeModal();
      }
    }
  }, [props.createLead]);

  const handleCancel = () => {
    // Perform any necessary cleanup or actions
    setVisible(false);
    props.closeModal()
  };

    const onFinish = (values) => {
        const data = {
            provinceId: values.provinceId,
            leadData: {
                name: values.name,
                email: values.email,
                phone: values.phone,
                propertyType: values.propertyType,
                propertySize: values.propertySize,
                movingDate: values.movingDate,
                movingFrom: values.movingFrom,
                movingTo: values.movingTo,
                timeToCall: values.timeToCall
            },
            source: 'manual'
        }
        props.createLead_(data);
    }



  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <>
      <Modal
        title="Add Manual Leads"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width="700px"
      >
        <Form
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={()=>{}}
            autoComplete="off"
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="Lead Province:"
                        name="provinceId"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Province"
                        >
                            <Option value="1">Alberta</Option>
                            <Option value="BC">British Columbia</Option>
                            <Option value="3">Manitoba</Option>
                            <Option value="NB">New Brunswick</Option>
                            <Option value="NL">Newfoundland and Labrador</Option>
                            <Option value="NS">Nova Scotia</Option>
                            <Option value="NT">Northwest Territories</Option>
                            <Option value="NU">Nunavut</Option>
                            <Option value="2">Ontario</Option>
                            <Option value="PE">Prince Edward Island</Option>
                            <Option value="QC">Quebec</Option>
                            <Option value="SK">Saskatchewan</Option>
                            <Option value="YT">Yukon</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Email:"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                                type: 'email'
                            },
                        ]}
                    >
                        <Input type='email' />
                    </Form.Item>
                    <Form.Item
                        label="Property Type:"
                        name="propertyType"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Moving date"
                        name="movingDate"
                        getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                        getValueProps={(i) => moment(i)}
                        rules={[
                            {
                            required: true,
                            message: <p> This field is required</p>,
                            },
                        ]}
                        >
                        <DatePicker format='YYYY-MM-DD' />
                    </Form.Item>
                    <Form.Item
                        label="Moving From:"
                        name="movingFrom"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Property Size:"
                        name="propertySize"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Time to call:"
                        name="timeToCall"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Moving To:"
                        name="movingTo"
                        rules={[
                            {
                                required: true,
                                message: '!!!Please enter value',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "end" }}>
                        <Button type='default' htmlType="cancel" style={{marginRight: "10px"}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                        </Form.Item>
                </Col>
            </Row>
        </Form>
        <br />
        <br />
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  createLead: state.createLead.response
})

const mapDispatchToProps = dispatch => ({
  createLead_: (data)=>{
      dispatch(createLead(data));
  }
}) 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualLeadsModal);