import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Typography, Table } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllTXn } from '../store/action/business/TxnAction';
import { getAllTXnAdmin } from '../store/action/admin/TxnAdminAction';
import { render } from '@testing-library/react';
import downloadInvoice from '../utils/printInvoice';

const TransactionPage = (props) => {
    const { Title, Text } = Typography;
    const [transactions, setTransactions] = useState([]);
    const [walletAmount, setWalletAmount] = useState(props.wallet?.response?.walletAmount || 0);
    const [depositAmount, setDepositAmount] = useState(0);


    useEffect(() => {
        if (props.user.role === "admin") {
            props.getAdminTxns_();
        } else {
            props.getTxns_();
        }
    }, []);
    useEffect(() => {
        if (props.user.role === "admin" && props.transactionsAdmin && props.transactionsAdmin.transactions && props.transactionsAdmin.transactions.length > 0) {
            setTransactions(props.transactionsAdmin.transactions);
         }else if(props.user.role !== "admin" && props.transactions && props.transactions.transactions && props.transactions.transactions.length > 0){
            setTransactions(props.transactions.transactions);
        }
    }, [props.transactions, props.transactionsAdmin]);
    useEffect(() => {
        if (props.user.role === 'business' && props.wallet && props.wallet.response && props.wallet.response.walletAmount) {
            setWalletAmount(props.wallet.response.walletAmount);
        }
      }, [props.wallet]);
      

    const lastDepositedDate = transactions && transactions.length > 0 ? transactions.reduce((acc, curr) => {
        if (curr.type === 'credit' && moment(curr.createdAt).isAfter(acc)) {
            return curr.createdAt;
        }
        return acc;
    }) : "N/A";

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'transactionId',
            render: (date) => moment(date).format('YYYY-MM-DD'),
        },
        {
            title: 'Transaction Amount',
            key: 'amount',
            render: (record) => (
                <span style={{ color: record.transactionType === 'credit' ? 'green' : 'red' }}>
                    {record.transactionType === 'credit' ? '+' : '-'} ${record.amount.$numberDecimal}
                </span>
            ),
        },
        ...(props.user.role === 'admin' ? [{
            title: 'Business Name',
            key: 'amount',
            dataIndex: ['name'],
        }] : []),
        {
            title: 'Notes',
            render: (record) => record.transactionType === 'credit' ? 'Wallet Deposit' : 'Deduction for Lead',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => status === 'paid' || record.transactionType === "debit" ? 'Completed' : 'Pending',
        },
        {
            title: 'Ref Id',
            render: (record) => props.user && props.user.role && props.user.role !== "admin" && record.transactionType === "credit" ? <div onClick={()=>downloadInvoice({
                number: record.transactionId,
                date: moment(record.createdAt).format('YYYY-MM-DD'),
                customerName: record.name,
                items: [
                    {
                        name: 'Wallet Deposit',
                        quantity: 1,
                        price: parseFloat(record.amount.$numberDecimal.toString()),
                    }
                ],
            })} style={{ color: 'blue', cursor: 'pointer' }}>{record.transactionId}</div> : record.transactionId,
        },
    ];

    return (
        <div style={{ padding: 20 }}>
            <Title level={2}>Transaction Page</Title>
            {/* <Row gutter={20} style={{ width: '100%' }}>
                <Col span={6}>
                    <Card
                        style={{
                            height: '100%',
                            backgroundColor: '#4CAF50',
                            borderRadius: 10,
                        }}
                    >
                        <Statistic
                            value={ props.user.role !== "admin" ? depositAmount : walletAmount }
                            prefix="$"
                            suffix="CAD"
                            style={{ color: '#fff' }}
                        />
                        <Text type="secondary" style={{ color: '#fff' }}>
                            {props.user.role !== "admin" ? "Wallet Amount" : "Total Amoun Received"}
                        </Text>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        style={{
                            height: '100%',
                            backgroundColor: '#2196F3',
                            borderRadius: 10,
                        }}
                    >
                        <Statistic
                            value={moment(lastDepositedDate).format('YYYY-MM-DD')}
                            style={{ color: '#fff' }}
                        />
                        <Text type="secondary" style={{ color: '#fff' }}>
                            Last Deposited Date
                        </Text>
                    </Card>
                </Col>
            </Row> */}
            <Row gutter={20} style={{ marginTop: 20 }}>
                <Col span={24}>
                    <Card title="Transactions" style={{ height: '100%' }}>
                        <Table
                            columns={columns}
                            dataSource={transactions}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user.response,
    transactions: state.allTxn.response,
    transactionsAdmin: state.allTxnAdmin.response,
    wallet: state.wallet
})

const mapDispatchToProps = dispatch => ({
    getTxns_: () => {
        dispatch(getAllTXn());
    },
    getAdminTxns_: () => {
        dispatch(getAllTXnAdmin());
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionPage);
