import * as type from '../../types';

const initialAdminCardsState = {
    response: {},
    loading: false,
    error: null
}
const initialThisWeekPaymentState = {
    response: {},
    loading: false,
    error: null
}
const initialThisWeekBusinessState = {
    response: {},
    loading: false,
    error: null
}
export default function adminCardReducer(state = initialAdminCardsState, action) {
    switch (action.type) {
        case type.FETCH_ALL_ADMIN_CARDS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_ALL_ADMIN_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.cardData.data
            }
        case type.FETCH_ALL_ADMIN_CARDS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}

export function thisWeekPaymentsReducer(state = initialThisWeekPaymentState, action) {
    switch (action.type) {
        case type.FETCH_THIS_WEEK_PAYMENTS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_THIS_WEEK_PAYMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_THIS_WEEK_PAYMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}

export function thisWeekBusinessReducer(state = initialThisWeekBusinessState, action) {
    switch (action.type) {
        case type.FETCH_THIS_WEEK_BUSINESS:
            return {
                ...state,
                loading: true
            }
        case type.FETCH_THIS_WEEK_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.data.data
            }
        case type.FETCH_THIS_WEEK_BUSINESS_FAILURE:
            return {
                ...state,
                loading: false,
                response: action.message
            }
        default:
            return state
    }
}
