import { Table, Button, Modal, Row, Col } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import RaiseDuplicateModal from '../Modals/RaiseDuplicateModal';
import moment from 'moment';

const BusinessesTable = (props) => {
  const [recordId, setRecordId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false); // Add this line

  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'userId',
    },
    {
      title: 'Registered Date',
      key: 'userId',
      render: (record) => (
        <span>{moment(record.createdAt).format('YYYY-MM-DD')}</span>
      ),
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'userId',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'userId',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Button type="link" danger onClick={() => { setRecordId(record.id); setConfirmModalVisible(true) }}>
          Block Business
        </Button>
      ),
    },
  ];

  const handleConfirm = () => {
    // Add your code to block the business here
    // For example, you can make an API call to update the business status in the database

    setConfirmModalVisible(false);
    setModalVisible(true);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.data}
        key={record => record.id}
        pagination={!props.mini}
        expandable={props.expand ? {
          expandedRowRender: (record) => (
            <>
              <Row>
                <Col span={4}>How many jobs are you able to complete each day:</Col>
                <Col span={6}>{record.jobsPerDay}</Col>
                <Col span={3}>Team Strength:</Col>
                <Col span={8}>{record.teamStrength}</Col>
              </Row>
              <br></br>
              <Row>
                <Col span={4}> Company's Location:</Col>
                <Col span={6}>{record.location}</Col>
                <Col span={3}> GMB Profile URL:</Col>
                <Col span={6}>{record.gmbProfileUrl}</Col>
              </Row>
            </>

          ),
        } : false}
        footer={() => props.mini ? (
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <Link to="/admin/businesses">View More</Link>
            </td>
          </tr>
        ) : null}
      />
      <Modal
        title="Block Business"
        visible={confirmModalVisible}
        onOk={handleConfirm}
        onCancel={() => setConfirmModalVisible(false)}
      >
        <p>Are you sure you want to block this business?</p>
      </Modal>
    </div>
  );
};

export default BusinessesTable;